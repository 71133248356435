import React, { Fragment } from "react";
import './css/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import AppRoutes from "./AppRoutes";
// import { BrowserRouter } from "react-router-dom";
const BrowserRouter = require("react-router-dom").BrowserRouter;
// import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
function App() {
  console.log(process.env.REACT_APP_ENV);
  console.log(process.env);
  
  return (
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
  );
}

export default App;
